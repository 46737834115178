<template>
    <div class="table_box">
        <el-form :inline="true">
            <el-form-item label="项目名称：">
                <el-input v-model="projectName" placeholder="点击输入项目名称"></el-input>
            </el-form-item>
            <el-form-item label="企业名称：">
                <el-input v-model="company" placeholder="点击输入企业名称"></el-input>
            </el-form-item>
            <el-form-item label="项目地址：">
                <el-input v-model="address" placeholder="点击输入项目地址"></el-input>
            </el-form-item>
            <div class="btn_box">
                <el-button
                class="search_btn"
                type="primary"
                size="small"
                @click="searchData"
                >查询</el-button
                >
                <el-button class="search_btn" size="small" @click="reset"
                >重置</el-button
                >
            </div>
        </el-form>
        <el-table ref="table" width="910" :data="tableData" border stripe  :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
            <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
            <el-table-column prop="projectName" label="项目名称"></el-table-column>
            <el-table-column prop="company" label="企业名称" width="240"></el-table-column>
            <el-table-column prop="address" label="项目地址" width="240"></el-table-column>
            <el-table-column prop="area" label="总面积(m²)"></el-table-column>
            <el-table-column prop="floorCountTotal" label="总层数"></el-table-column>
            <el-table-column prop="floorCountUp" label="地上总层数"></el-table-column>
            <el-table-column prop="floorCountDown" label="地下总层数"></el-table-column>
        </el-table>
        <div class="page-box">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              background
              layout="prev,pager,next,total,sizes,jumper"
              :page-sizes="[10, 50, 100]"
              :total="total"
              :current-page="pageNum"
            >
            </el-pagination>
        </div>
    </div>

</template>

<script>

import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations} from 'vuex'
export default {
    props: {
        osData:{},
    },
    data() {
        return {
            buildingName:'',
            projectName:'',
            tableData:[],
            pageNum:1,
            pageSize:10,
            total:0,
            address:'',
            company:'',
            saleYear:'',
        }
    },
    created(){
    },
    mounted(){
        var timestr = new Date(this.osData.saleDate);
        this.saleYear = timestr.getFullYear();
        console.log(this.saleYear)
        this.getListData();
    },
    methods:{
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'policyCaliber',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //获取表格数据
        getListData() {
            this.axios({
                method: "post",
                url: "/networkProject/region/pageSameProjectOfYear",
                data: {
                    city: this.osData.city,
                    province: this.osData.province,
                    projectName:this.projectName,
                    year: this.saleYear,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    company:this.company,
                    address:this.address,
                },
            }).then((res) => {
                if(res.data.data){
                    var $data = res.data.data;
                    console.log($data)
                    if($data.records){
                        this.tableData = $data.records;
                        this.total = $data.total
                    }else{
                        this.tableData = [];
                        this.total = 0;
                    }
                }

            });
        },
        searchData(){
            this.pageNum = 1;
            this.getListData();
        },
        reset(){
            this.projectName = '';
            this.company = '';
            this.address = '';
        },
        //切换条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getListData();
        },
        //切换页码
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getListData();
        },
      
    }
}
</script>

<style scoped  lang="less">
.table_box {
  padding: 16px 24px;
  /deep/.el-form-item__content {
    width: 175px;
  }
  .el-form-item {
    margin-bottom: 12px !important;
  }
  .rate{
      margin-right: 0 !important;
      /deep/.el-form-item__content{
        width: 57px;   
      }
      /deep/ .el-input__inner{
          width: 57px;
      }
  }
  .info{
      overflow: hidden;
      margin-bottom: 16px;
      div{
          float: left;
          font-size: 14px;
      }
      .total{
          font-weight: 600;
          margin-right: 16px;
      }
      .info_item{
          margin-right: 24px;
      }
  }
}
.btn_box{
    float: right;
    padding-top: 5px;
}
/deep/ th {
  padding: 4px;
}
/deep/ .el-table__cell {
  padding: 5px;
}
</style>