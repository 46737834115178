<template>
    <div class="table_box">
        <el-form :inline="true">
            <el-form-item label="证书编号：">
                <el-input v-model="licenseNo" placeholder="点击输入证书编号"></el-input>
            </el-form-item>
            <el-form-item label="证书时间：">
                <el-date-picker class="date_select"
                    v-model="preDate"
                    @change="change"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <div class="btn_box">
                <el-button
                class="search_btn"
                type="primary"
                size="small"
                @click="searchData"
                >查询</el-button
                >
                <el-button class="search_btn" size="small" @click="reset"
                >重置</el-button
                >
            </div>
        </el-form>
        <div class="info">
            <div class="total">合计</div>
            <div class="info_item">证书个数合计：{{buildData.totalLicenseCount||'0'}}个</div>
            <div class="info_item">使用权面积合计：{{buildData.totalArea||'0'}}m²</div>
        </div>
        <el-table ref="table" width="910" :data="tableData" border stripe  :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
            <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
            <el-table-column prop="licenseType" label="证书类型" width="130">
                <template slot-scope="scope">
                    {{typeData[scope.row.licenseType]}}
                </template>
            </el-table-column>
            <el-table-column prop="licenseNumber" label="证书编号" width="130"></el-table-column>
            <el-table-column prop="issuingAuthority" label="发证机关"></el-table-column>
            <el-table-column prop="issuingDate" label="发证日期" width="120">
                <template slot-scope="scope">
                    <span>{{ scope.row.issuingDate?utils.formatDateDay(scope.row.issuingDate):'' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="area" label="建设规模(m²)"></el-table-column>
        </el-table>
        <div class="page-box">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              background
              layout="prev,pager,next,total,sizes,jumper"
              :page-sizes="[10, 50, 100]"
              :total="total"
              :current-page="pageNum"
            >
            </el-pagination>
        </div>
    </div>

</template>

<script>

import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations} from 'vuex'
export default {
    props: {
        osData:{},
    },
    data() {
        return {
            licenseNo:'',
            preDate:[],
            projectName:'',
            buildData:{},
            tableData:[],
            pageNum:1,
            pageSize:10,
            total:0,
            startDate:'',
            endDate:'',
            typeData:{
                1:'土地使用权证',
                3:'建设工程规划许可证',
                4:'建设施工许可证',
                6:'预售许可证信息',
                7:'不动产权证书'
            },
      
        }
    },
    created(){
    },
    mounted(){
        this.projectName = this.osData.projectName;
        this.getListData();
    },
    methods:{
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'policyCaliber',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //获取表格数据
        getListData() {
            this.axios({
                method: "post",
                url: "/networkProject/pageForLicense",
                data: {
                    city: this.osData.city,
                    province: this.osData.province,
                    projectName:this.projectName,
                    licenseNo: this.licenseNo,
                    licenseType:3,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    startDate:this.startDate,
                    endDate:this.endDate,
                },
            }).then((res) => {
                var $data = res.data.data;
                console.log($data)
                if($data.overView){
                    this.buildData = $data.overView;
                    this.tableData = $data.pagination.records;
                    this.total = $data.pagination.total
                }else{
                    this.tableData = [];
                    this.total = 0;
                    this.buildData = {};
                }

            });
        },
        searchData(){
            this.pageNum = 1;
            if(this.preDate==null){
                this.preDate = []
            }
            this.getListData();
        },
        reset(){
            this.licenseNo = '';
            this.preDate = [];
            this.startDate = ''
            this.endDate = ''
     
        },
        change(val){
            if (val == null){
                this.preDate = [];
                val = [];
            } 
            if(this.preDate.length>0){
                this.startDate = this.preDate[0]
                this.endDate = this.preDate[1]
            }else{
                this.startDate = ''
                this.endDate = ''
            }
        },
        //切换条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getListData();
        },
        //切换页码
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getListData();
        },
      
    }
}
</script>

<style scoped  lang="less">
.table_box {
  position: relative;
  padding: 16px 24px;
  /deep/.el-form-item__content {
    width: 175px;
  }
  .el-form-item {
    margin-bottom: 12px !important;
  }
  .rate{
      margin-right: 0 !important;
      /deep/.el-form-item__content{
        width: 57px;   
      }
      /deep/ .el-input__inner{
          width: 57px;
      }
  }
  .info{
      overflow: hidden;
      margin-bottom: 16px;
      div{
          float: left;
          font-size: 14px;
      }
      .total{
          font-weight: 600;
          margin-right: 16px;
      }
      .info_item{
          margin-right: 24px;
      }
  }
}
.btn_box{
    float: right;
    padding-top: 5px;
    i{
        cursor: pointer;
        margin-left: 24px;
    }
}
/deep/ th {
  padding: 4px;
}
/deep/ .el-table__cell {
  padding: 5px;
}
.el-input__inner.date_select{
    width: 240px;
}
.col_more{
    width: 214px;
    height: 252px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
    position: absolute;
    top: 50px;
    right: 5px;
    box-sizing: border-box;
    padding: 14px 0;
    z-index: 99;
    .reset{
        position: absolute;
        top: 17px;
        right: 17px;
        width: 28px;
        height: 20px;
        font-size: 14px;
        color: #185BCE;
        cursor: pointer;
    }
    /deep/.el-table td,.el-table th.is-leaf,.el-table--border,.el-table--group {
        border: none;
        cursor: pointer;
    }
    .el-table::before {
        height: 0;
    }
    .title{
        font-size: 16px;
        color: #323233;
        margin-bottom: 15px;
        padding-left: 11px;
        font-weight: 600;
    }
    
}
</style>