import { render, staticRenderFns } from "./osdetail.vue?vue&type=template&id=39cf2905&scoped=true&"
import script from "./osdetail.vue?vue&type=script&lang=js&"
export * from "./osdetail.vue?vue&type=script&lang=js&"
import style0 from "./osdetail.vue?vue&type=style&index=0&id=39cf2905&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39cf2905",
  null
  
)

export default component.exports