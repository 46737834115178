<template>
  <div class="base-container">
    <div class="main-wrapper">
      <div class="tag">
        网签数据 / <span style="color:#646566">{{osData.projectName}}</span>
        <el-button class="backbtn" type="text" @click="back()">返回上级</el-button>
      </div>
      <div class="list_box">
        <div class="header">
          <p class="title">{{osData.projectName}}</p>
          <div class="item_box">
            <div class="item" :title="osData.company">开发商名称：<span>{{osData.company}}</span></div>
            <div class="item">区域：<span>{{osData.province+osData.city+osData.county}}</span></div>
            <div class="item" :title="osData.address">地址：<span>{{osData.address}}</span></div>
            <div class="item">已售数：<span>{{osData.soldCount}}套</span></div>
            <div class="item">未售数：<span>{{osData.unsaleCount}}套</span></div>
            <div class="item">不可售数：<span>{{osData.remainCount}}套</span></div>
            <div class="item">预售许可证主要销售年度：<span>{{yearsData.minSaleYear||'-'}}~{{yearsData.maxSaleYear||'-'}}</span></div>
            <div class="item">施工许可证主要建设年度：<span>{{yearsData.minConstructYear||'-'}}~{{yearsData.maxConstructYear||'-'}}</span></div>
          </div>
        </div>
      </div>
      <div class="list_box">
        <div class="tab_box">
            <div class="tab-item" :class="{'selected':activeName == 'ldxx'}" @click="titleTab('ldxx')">楼栋信息</div>
            <div class="tab-item" :class="{'selected':activeName == 'fwxx'}" @click="titleTab('fwxx')">房屋信息</div>
            <div class="tab-item" :class="{'selected':activeName == 'zjxx'}" @click="titleTab('zjxx')">证件信息</div>
            <div class="tab-item" :class="{'selected':activeName == 'xmxx'}" @click="titleTab('xmxx')">同年同期项目信息</div>
        </div>
        <buildingInformation :osData="osData" v-if="activeName == 'ldxx'"></buildingInformation>
        <house :osData="osData" v-if="activeName == 'fwxx'"></house>
        <license :osData="osData" v-if="activeName == 'zjxx'"></license>
        <sameProjectOfYear :osData="osData" v-if="activeName == 'xmxx'"></sameProjectOfYear>
      </div>
    </div>
  </div>
</template>

<script>
import { getStore, setStore } from "@/util/store";
import { mapMutations } from "vuex";
import buildingInformation from '@/views/onlineSignature/buildingInformation.vue'
import house from '@/views/onlineSignature/house.vue'
import sameProjectOfYear from '@/views/onlineSignature/sameProjectOfYear.vue'
import license from '@/views/onlineSignature/license.vue'
export default {
  name: "index",
  components: {
      buildingInformation,
      house,
      sameProjectOfYear,
      license
  },
  data() {
    return {
      projectName: "",
      osData:{},
      yearsData:{},
      activeName:'ldxx',
      
    };
  },
  created() {
    this.osData = getStore('osData')
    this.getYears();
  },
  destroyed() {},
  mounted() {},
  methods: {
    ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
    back() {
          this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
          var $path = getStore("osfrom");
          this.$router.push({path: $path});
      },
    //tab切换
    titleTab(val){
      this.activeName = val;
    },
    //预售许可证主要销售年度&施工许可证主要建设年度
    getYears() {
      this.axios({
        method: "post",
        url: "/networkProject/getMainYearOfLicense",
        data: {
          province: this.osData.province,
          city: this.osData.city,
          projectName: this.osData.projectName,
        },
      }).then((res) => {
        this.yearsData = res.data.data;
      });
    },
    
  
    
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.tag {
  color: #969799;
  margin: 16px 0;
  position: relative;
}
.backbtn{
  position: absolute;
  right: 0;
  top: -10px;
}
.list_box {
  width: 100%;
  height: auto;
  background: #ffffff;
  margin-bottom: 24px;
  .header{
    width: 100%;
    height: auto;
    padding: 24px 92px 8px;
    .title{
      font-size: 18px;
      color: #323233;
      margin-bottom: 24px;
    }
    .item_box{
      width: 100%;
      overflow: hidden;
      .item{
        width: 330px;
        float: left;
        color: #969799;
        margin-right: 24px;
        margin-bottom: 16px;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        span{
          color: #646566;
        }
      }
    }
  }
  .tab_box{
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #EBEBEB;
      .tab-item{
          float: left;
          margin-left: 24px;
          margin-top: 18px;
          cursor: pointer;
          font-size: 16px;
      }
      
      .selected {
          color: #185BCE;
      }
  }
}
.search-box {
  display: flex;
  justify-content: space-between;
  padding: 10px 24px 0;
  border-bottom: 1px solid #ebebeb;
  .el-form-item {
    margin-bottom: 11px !important;
  }
}
.module-title {
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}
.el-cascader {
  line-height: 30px;
}
/deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 0 12px;
}
/deep/.el-form-item__label {
  margin-top: 2px;
  padding-right: 0px !important;
}
/deep/.el-input__icon {
  line-height: 32px;
}

</style>