<template>
  
    <div class="list_box">
      <div class="tab_box">
          <div class="tab-item" :class="{'selected':activeName == 'ysxkz'}" @click="titleTab('ysxkz')">预售许可证信息({{countData.presaleLicenseCount}})</div>
          <div class="tab-item" :class="{'selected':activeName == 'tdsyqz'}" @click="titleTab('tdsyqz')">土地使用权证({{countData.landUseLicenseCount}})</div>
          <div class="tab-item" :class="{'selected':activeName == 'bdcqzs'}" @click="titleTab('bdcqzs')">不动产权证书({{countData.realPropertyLicenseCount}})</div>
          <div class="tab-item" :class="{'selected':activeName == 'gcghxkz'}" @click="titleTab('gcghxkz')">建设工程规划许可证({{countData.projectPlanningLicenseCount}})</div>
          <div class="tab-item" :class="{'selected':activeName == 'sgxkz'}" @click="titleTab('sgxkz')">建设施工许可证({{countData.constructionLicenseCount}})</div>
      </div>
      <presalePermit :osData="osData" v-if="activeName == 'ysxkz'"></presalePermit>
      <landUseLicense :osData="osData" v-if="activeName == 'tdsyqz'"></landUseLicense>
      <realPropertyLicense :osData="osData" v-if="activeName == 'bdcqzs'"></realPropertyLicense>
      <projectPlanningLicense :osData="osData" v-if="activeName == 'gcghxkz'"></projectPlanningLicense>
      <constructionLicense :osData="osData" v-if="activeName == 'sgxkz'"></constructionLicense>
  
    </div>
 
</template>

<script>
import { getStore, setStore } from "@/util/store";
import { mapMutations } from "vuex";
import presalePermit from '@/views/onlineSignature/pageForLicense/presalePermit.vue'
import landUseLicense from '@/views/onlineSignature/pageForLicense/landUseLicense.vue'
import realPropertyLicense from '@/views/onlineSignature/pageForLicense/realPropertyLicense.vue'
import projectPlanningLicense from '@/views/onlineSignature/pageForLicense/projectPlanningLicense.vue'
import constructionLicense from '@/views/onlineSignature/pageForLicense/constructionLicense.vue'
export default {
  name: "index",
  components: {
      presalePermit,
      landUseLicense,
      realPropertyLicense,
      projectPlanningLicense,
      constructionLicense
  },
  data() {
    return {
      projectName: "",
      osData:{},
      countData:{},
      activeName:'ysxkz',
    };
  },
  created() {
    this.osData = getStore('osData')
    this.getCount();
  },
  destroyed() {},
  mounted() {},
  methods: {
    ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
    //tab切换
    titleTab(val){
      this.activeName = val;
    },
    //预售许可证主要销售年度&施工许可证主要建设年度
    getCount() {
      this.axios({
        method: "post",
        url: "/networkProject/statAllLicenseCount",
        data: {
          province: this.osData.province,
          city: this.osData.city,
          projectName: this.osData.projectName,
        },
      }).then((res) => {
        this.countData = res.data.data;
      });
    },
    
  
    
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.tag {
  color: #969799;
  margin: 16px 0;
}
.list_box {
  width: 100%;
  height: auto;
  padding-top: 16px;
  box-sizing: border-box;
  .tab_box{
      width: 100%;
      height: 27px;
      padding-left: 24px;
      box-sizing: border-box;
      .tab-item{
          float: left;
          width: 179px;
          height: 27px;
          line-height: 27px;
          background: rgba(50, 50, 51, 0.06);
          color: #646566;
          cursor: pointer;
          border-right: 1px solid #D8D8D8;
          text-align: center;
      }
      .tab-item:last-child{
        border-right: none;
      }
      
      .selected {
          background: #185BCE;
          color: #fff;
      }
  }
}

</style>