<template>
    <div class="table_box">
        <el-form :inline="true">
            <el-form-item label="幢号：">
                <el-input v-model="buildingName" placeholder="点击输入幢号"></el-input>
            </el-form-item>
            <el-form-item label="单元：">
                <el-select v-if="isUnit" v-model="unit" placeholder="点击筛选单元">
                    <el-option
                        v-for="item in unitData"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                    </el-option>
                </el-select>
                <el-input v-else v-model="unit" placeholder="点击输入单元"></el-input>
            </el-form-item>
            <el-form-item label="房号：">
                <el-input v-model="houseId" placeholder="点击输入房号"></el-input>
            </el-form-item>
            <div class="btn_box">
                <el-button
                class="search_btn"
                type="primary"
                size="small"
                @click="searchData"
                >查询</el-button
                >
                <el-button class="search_btn" size="small" @click="reset"
                >重置</el-button
                >
            </div>
        </el-form>
        <div class="info">
            <div class="total">合计</div>
            <div class="info_item">总套数：{{buildData.totalHouseCount||'0'}}套</div>
            <div class="info_item">总面积：{{buildData.totalArea||'0'}}m²</div>
        </div>
        <el-table ref="table" width="910" :data="tableData" border stripe  :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
            <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
            <el-table-column prop="buildingName" label="幢号"></el-table-column>
            <el-table-column prop="houseUnit" label="单元" width="110px"></el-table-column>
            <el-table-column prop="floor" label="所在层"></el-table-column>
            <el-table-column prop="houseNumber" label="房号"></el-table-column>
            <el-table-column prop="houseUsage" label="房屋用途"></el-table-column>
            <el-table-column prop="saleType" label="房屋销售类型"></el-table-column>
            <el-table-column prop="area" label="建筑面积(m²)"></el-table-column>
            <el-table-column prop="areaInner" label="套内面积(m²)"></el-table-column>
            <el-table-column prop="preditAreaShare" label="分摊建筑面积(m²)"></el-table-column>
            <el-table-column prop="price" label="价格(元)" width="90px"></el-table-column>
         
          
        </el-table>
        <div class="page-box">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              background
              layout="prev,pager,next,total,sizes,jumper"
              :page-sizes="[10, 50, 100]"
              :total="total"
              :current-page="pageNum"
            >
            </el-pagination>
        </div>
    </div>

</template>

<script>

import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations} from 'vuex'
export default {
    props: {
        osData:{},
    },
    data() {
        return {
            buildingName:'',
            projectName:'',
            buildData:{},
            tableData:[],
            pageNum:1,
            pageSize:10,
            total:0,
            unit:'',
            unitData:[],
            houseId:'',
            houseIdData:[],
            isUnit:true
        }
    },
    created(){
    },
    mounted(){
        this.projectName = this.osData.projectName;
        this.getUnit();
        this.getListData();
    },
    methods:{
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'policyCaliber',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //获取单元
        getUnit(){
            this.axios({
                method: "post",
                url: "/networkProject/listUnitInfo",
                data: {
                    city: this.osData.city,
                    province: this.osData.province,
                    projectName:this.projectName,
                    buildingName: this.buildingName,
                },
            }).then((res) => {
                if(res.data.data){
                    this.unitData = res.data.data;
                    console.log(this.unitData.length)
                    if(this.unitData.length==0){
                        this.isUnit = false;
                    }else{
                        this.isUnit = true;
                    }
                }else{
                    this.unitData = [];
                    this.isUnit = false;
                }
                

            });
        },
        //获取表格数据
        getListData() {
            this.axios({
                method: "post",
                url: "/networkProject/pageForHouse",
                data: {
                    city: this.osData.city,
                    province: this.osData.province,
                    projectName:this.projectName,
                    buildingName: this.buildingName,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    unit:this.unit,
                    roomName:this.houseId,
                },
            }).then((res) => {
                var $data = res.data.data;
                if($data.overView){
                    this.buildData = $data.overView;
                    this.tableData = $data.pagination.records;
                    this.total = $data.pagination.total
                }else{
                    this.tableData = [];
                    this.total = 0;
                    this.buildData = {};
                }

            });
        },
        searchData(){
            this.pageNum = 1;
            this.getListData();
        },
        reset(){
            this.buildingName = '';
            this.unit = '';
            this.houseId = '';
        },
        //切换条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getListData();
        },
        //切换页码
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getListData();
        },
      
    }
}
</script>

<style scoped  lang="less">
.table_box {
  padding: 16px 24px;
  /deep/.el-form-item__content {
    width: 175px;
  }
  .el-form-item {
    margin-bottom: 12px !important;
  }
  .rate{
      margin-right: 0 !important;
      /deep/.el-form-item__content{
        width: 57px;   
      }
      /deep/ .el-input__inner{
          width: 57px;
      }
  }
  .info{
      overflow: hidden;
      margin-bottom: 16px;
      div{
          float: left;
          font-size: 14px;
      }
      .total{
          font-weight: 600;
          margin-right: 16px;
      }
      .info_item{
          margin-right: 24px;
      }
  }
}
.btn_box{
    float: right;
    padding-top: 5px;
}
/deep/ th {
  padding: 4px;
}
/deep/ .el-table__cell {
  padding: 5px;
}
</style>