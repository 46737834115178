<template>
    <div class="table_box">
        <el-form :inline="true">
            <el-form-item label="幢号：">
                <el-input v-model="buildingName" placeholder="点击输入幢号"></el-input>
            </el-form-item>
            <el-form-item class="rate" label="销售进度：">
                <el-input v-model="minSalesProgress" placeholder="下限"></el-input>
            </el-form-item>
            <span style="line-height:42px"> %~ </span>
            <el-form-item   el-form-item class="rate">
                <el-input  v-model="maxSalesProgress" placeholder="上限"></el-input>
            </el-form-item>
            <span style="line-height:42px"> %</span>
            <div class="btn_box">
                <el-button
                class="search_btn"
                type="primary"
                size="small"
                @click="searchData"
                >查询</el-button
                >
                <el-button class="search_btn" size="small" @click="reset"
                >重置</el-button
                >
            </div>
        </el-form>
        <div class="info">
            <div class="total">合计</div>
            <div class="info_item">总楼栋数：{{buildData.buildingCount||'0'}}栋</div>
            <div class="info_item">总面积：{{buildData.totalArea||'0'}}m²</div>
            <div class="info_item">总层数：{{buildData.totalFloorCount||'0'}}层</div>
            <div class="info_item">地上总层数：{{buildData.totalFloorUpCount||'0'}}层</div>
            <div class="info_item">地下总层数：{{buildData.totalFloorDownCount||'0'}}层</div>
        </div>
        <el-table ref="table" width="910" :data="tableData" border stripe  :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
            <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
            <el-table-column prop="buildingName" label="幢号"></el-table-column>
            <el-table-column prop="houseStructure" label="房屋结构" width="110"></el-table-column>
            <el-table-column prop="buildingUsage" label="规划用途"></el-table-column>
            <el-table-column prop="area" label="建筑面积(m²)"></el-table-column>
            <el-table-column prop="floorCountTotal" label="总层数" width="75"></el-table-column>
            <el-table-column prop="houseCountTotal" label="房屋总套数" width="105"></el-table-column>
            <el-table-column prop="availableCount" label="可售套数" width="90"></el-table-column>
            <el-table-column prop="remainCount" label="不可售套数"></el-table-column>
            <el-table-column prop="unsaleCount" label="未售房屋套数"></el-table-column>
            <el-table-column prop="soldCount" label="已售房屋套数"></el-table-column>
            <el-table-column label="销售进度" width="90px">
                 <template slot-scope="scope">
                    <span>{{ scope.row.salePercent }}%</span>
                </template>
            </el-table-column>
         
          
        </el-table>
        <div class="page-box">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              background
              layout="prev,pager,next,total,sizes,jumper"
              :page-sizes="[10, 50, 100]"
              :total="total"
              :current-page="pageNum"
            >
            </el-pagination>
        </div>
    </div>

</template>

<script>

import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations} from 'vuex'
export default {
    props: {
        osData:{},
    },
    data() {
        return {
            buildingName:'',
            maxSalesProgress:'',
            minSalesProgress:'',
            projectName:'',
            buildData:{},
            tableData:[],
            pageNum:1,
            pageSize:10,
            total:0,
        }
    },
    created(){
    },
    mounted(){
        this.projectName = this.osData.projectName;
        this.getListData();
    },
    methods:{
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'policyCaliber',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //获取表格数据
        getListData() {
            this.axios({
                method: "post",
                url: "/networkProject/pageForBuilding",
                data: {
                city: this.osData.city,
                province: this.osData.province,
                projectName:this.projectName,
                minSalesProgress: this.minSalesProgress,
                maxSalesProgress: this.maxSalesProgress,
                buildingName: this.buildingName,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                },
            }).then((res) => {
                var $data = res.data.data;
                console.log($data)
                if($data.overView){
                    this.buildData = $data.overView;
                    this.tableData = $data.pagination.records;
                    this.total = $data.pagination.total
                }else{
                    this.tableData = [];
                    this.total = 0;
                    this.buildData = {};
                }

            });
        },
        searchData(){
            this.pageNum = 1;
            this.getListData();
        },
        reset(){
            this.buildingName = '';
            this.minSalesProgress = '';
            this.maxSalesProgress = '';
        },
        //切换条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getListData();
        },
        //切换页码
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getListData();
        },
      
    }
}
</script>

<style scoped  lang="less">
.table_box {
  padding: 16px 24px;
  /deep/.el-form-item__content {
    width: 175px;
  }
  .el-form-item {
    margin-bottom: 12px !important;
  }
  .rate{
      margin-right: 0 !important;
      /deep/.el-form-item__content{
        width: 57px;   
      }
      /deep/ .el-input__inner{
          width: 57px;
      }
  }
  .info{
      overflow: hidden;
      margin-bottom: 16px;
      div{
          float: left;
          font-size: 14px;
      }
      .total{
          font-weight: 600;
          margin-right: 16px;
      }
      .info_item{
          margin-right: 24px;
      }
  }
}
.btn_box{
    float: right;
    padding-top: 5px;
}
/deep/ th {
  padding: 4px;
}
/deep/ .el-table__cell {
  padding: 5px;
}
</style>